<template>
  <div class="container">
    <div v-if="isnight===false">
      <!-- 屏幕音频 -->
      <ScreenAudio v-if="audioMaterialList.length" :materialList="audioMaterialList" @onPlay="startPlay" @onEnded="endPlay" />
      <!-- 屏幕视频 -->
      <ScreenVideo v-if="videoMaterialList.length" :materialList="videoMaterialList" @onPlay="startPlay" @onEnded="endPlay" />
      <!-- 屏幕运动排行榜 -->
      <ScreenRank v-if="runRankList.length" :runRankList="runRankList" @onEnded="endPlay" />
    </div>
    <div class="wan" v-if="isnight===true">
    </div>
  </div>
</template>

<script>
import { Queue, getUserIP } from '@/utils/util'
import { ScreenAudio, ScreenVideo, ScreenRank } from './components'
import WebsocketHeartbeatJs from 'websocket-heartbeat-js'

export default {
  data() {
    return {
      // 客户端ip地址，支持仅当前点播屏幕播放
      clientIp: '',
      // 节目清单
      materialObj: {},
      // 音频节目清单
      audioMaterialList: [],
      // 视频节目清单
      videoMaterialList: [],
      // 运动排行榜清单
      runRankList: [],
      isnight: false
    }
  },
  components: {
    ScreenAudio,
    ScreenVideo,
    ScreenRank
  },
  created() {
    this.watchIsNight()
    this.init()
  },
  computed: {
    // 用户点播节目列表
    broadcastMaterialList() {
      const { userId = '', devIp = '' } = this.materialObj
      // 支持仅当前点播屏幕播放
      return (userId && devIp === this.clientIp) ? [this.materialObj] : []
    },
    // 默认播放音频节目列表
    defaultAudioMaterialList() {
      return this.materialObj.defaultMaterialList.filter(item => item.type === 1) || []
    },
    // 默认播放视频节目列表
    defaultVideoMaterialList() {
      return this.materialObj.defaultMaterialList.filter(item => item.type === 2) || []
    },
    // 默认播放运动排名列表
    defaultRunRankList() {
      return this.materialObj.defaultMaterialList.filter(item => (item.type === 3 && item.duration !== 0)) || []
    }
  },
  methods: {
    init() {
      // 0.创建队列
      this.queue = new Queue()
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持WebSocket!')
        return
      }
      // 1.获取本地IP地址
      getUserIP((ip) => {
        this.clientIp = ip
        console.log('当前IP:', this.clientIp)
        this.connectWebSocket()
      })
    },
    connectWebSocket() {
      // 2.实例化socket
      this.websocketHeartbeatJs = new WebsocketHeartbeatJs({ url: `${this.$api.screenApi}/${this.clientIp}` })
      // 3.监听socket打开
      this.websocketHeartbeatJs.onopen = this.open
      // 4.监听socket关闭
      this.websocketHeartbeatJs.onclose = this.close
      // 5.监听socket错误信息
      this.websocketHeartbeatJs.onerror = this.error
      // 6.监听socket消息
      this.websocketHeartbeatJs.onmessage = this.getMessage
    },
    open() {
      console.info('connect success')
    },
    close(e) {
      console.error('WebSocket close:')
      // this.connectWebSocket()
    },
    error(e) {
      console.error('WebSocket error:')
      // this.connectWebSocket()
    },
    getMessage(ret) {
      const { data } = ret
      // 返回消息类型不是检测心跳，则入列
      if (data !== 'heartOk') {
        this.materialObj = JSON.parse(data) || {}
        console.info('节目清单(含默认和点播):', this.materialObj || '')
        this.enqueueMaterial()
      }
      this.watchIsNight()
    },
    // 栈，入队操作
    enqueueMaterial() {
      const { type = '' } = this.materialObj
      // 0.增加时间戳时段，防止数据没有变化子组件不渲染场景
      this.dyncMaterial()
      // 1.清空队列
      this.queue.clear()
      // 2.重新入列
      // 如果有点播节目，则入列并优先播放
      this.broadcastMaterialList.length &&
        this.queue.enqueue({ name: (type === 1) ? 'audioMaterialList' : 'videoMaterialList', list: this.broadcastMaterialList })
      // 如果有默认音频节目，则入队
      this.defaultAudioMaterialList.length &&
        this.queue.enqueue({ name: 'audioMaterialList', list: this.defaultAudioMaterialList })
      // 如果有默认视频节目，则入队
      this.defaultVideoMaterialList.length &&
        this.queue.enqueue({ name: 'videoMaterialList', list: this.defaultVideoMaterialList })
      // 如果有默认运动排行榜，则入队
      this.defaultRunRankList.length &&
        this.queue.enqueue({ name: 'runRankList', list: [{ path: '/runRink', duration: this.defaultRunRankList[0].duration }] })
      // 出队
      this.dequeueMaterial()
    },
    // 栈，出队操作
    dequeueMaterial() {
      this.resetList()
      // 栈是否为空，空重新入队循环播放
      if (this.queue.isEmpty()) {
        this.enqueueMaterial()
        return
      }
      // 出队，当前播放节目
      const curMateralList = this.queue.dequeue()
      this[curMateralList.name] = curMateralList.list
      console.info('当前节目:', curMateralList.list)
    },
    dyncMaterial() {
      this.materialObj.defaultMaterialList = this.materialObj?.defaultMaterialList.map(v => { return { ...v, _t: Date.now() } })
    },
    resetList() {
      this.audioMaterialList = []
      this.videoMaterialList = []
      this.runRankList = []
    },
    send(params) {
      // 用户点播节目开始和结束播放时发送消息
      console.info('播放开始/结束发送消息:', params)
      this.websocketHeartbeatJs.send(JSON.stringify(params))
    },
    startPlay(item = {}) {
      // 用户点播的节目开始播放时发送消息
      if (item.userId) {
        this.send({ "recordNo": item.recordNo, "userId": item.userId, "devIp": this.clientIp, "playStatus": 1 })
      }
    },
    endPlay(item = {}) {
      // 用户点播的节目结束播放时发送消息
      if (item.userId) {
        this.send({ "recordNo": item.recordNo, "userId": item.userId, "devIp": this.clientIp, "playStatus": 2 })
      }
      // 出队，播放下一节目列表
      this.dequeueMaterial()
    },
    watchIsNight() {
      let hours = new Date().getHours()
      if (hours > 21 || hours < 7) {
        this.isnight = true
      } else {
        this.isnight = false
      }
    }
  },
  beforeDestroy() {
    this.websocketHeartbeatJs.close()
  }
}
</script>
<style>
body {
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.container {
  .fullscreen-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .wan {
    background-color: #000;
    width: 100%;
    height: 100vh;
  }
}
</style>