<template>
  <div class="rank-wrap">
    <RunRink
    />
  </div>
</template>

<script>
  import RunRink from '@/views/guide/runRink';
  export default {
    props: {
      runRankList: {
        type: Array,
        default: []
      }
    },
    components: {
      RunRink
    },
    created () {
      const duration = (+this.runRankList[0]?.duration || 1) * 60 * 1000
      this.timer = setInterval(() => {
        this.onended()
      }, duration)
    },
    methods: {
      onended () {
        this.$emit('onEnded')
      }
    },
    beforeDestroy () {
      clearInterval(this.timer)
    }
  }
</script>

<style lang="scss" scoped>
.rank-wrap {
  width: 100%;
  height: 100%;
}
</style>