<template>
  <div class="audio-wrap">
    <el-carousel 
      ref="carousel"
      class="carousel" 
      :interval="5000" 
      height="100%" 
      arrow="never" 
      indicator-position="none"
      @mouseenter.native="delHandleMouseEnter"
    >
      <el-carousel-item v-for="(img, index) in curMaterialImgs" :key="index">
        <img class="carousel-img" :src="`${$api.fileUrl}/${img}`" alt=""/>
      </el-carousel-item>
    </el-carousel>
    <audio 
      ref="audioTag" 
      class="hidden" 
      :src="`${$api.fileUrl}/${curMaterialInfo.path}`" 
      preload="auto"
      @canplay="onCanplay(curMaterialInfo)"
      @play="onPlay" 
      @error="onError"
      @waiting="onWaiting"
      @pause="onPause" 
      @timeupdate="onTimeupdate" 
      @loadedmetadata="onLoadedmetadata"
      @ended="onended(curMaterialInfo)"
    />
  </div>
</template>

<script>
  export default {
    props: {
      materialList: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        curIndex: 0,
        curMaterialInfo: {},
        curMaterialImgs: []
      }
    },
    watch: {
      materialList: {
        handler: function (newList, oldList) {
          this.curIndex = 0
          this.curMaterialImgs = newList[this.curIndex]?.bannerPicture.split(";")
          this.curMaterialInfo = newList[this.curIndex]
          const audioElement = this.$refs.audioTag
          audioElement && audioElement.play()
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      onCanplay (item) {
        this.startPlay()
        // 用户点播,主动向websocket推送开始播放消息
        item.userId && this.$emit('onPlay', item)
      },
      onended (item) {
        // 如果有下一节目,继续播放下一节目
        this.curIndex = this.curIndex + 1
        if (this.curIndex <= this.materialList.length - 1) {
          this.curMaterialImgs = this.materialList[this.curIndex]?.bannerPicture.split(";")
          this.curMaterialInfo = this.materialList[this.curIndex]
          return false
        }
        // 结束播放消息
        this.$emit('onEnded', item)
      },
      // 开始播放
      startPlay () {
        this.$refs.audioTag.play()
      },
      // 音频暂停
      onPause () {
      },
      // 音频错误
      onError (err) {
        console.error('audio error:', err);
      },
      // 音频等待
      onWaiting (res) {
      },
      // 音频播放
      onPlay (res) {
      },
      // timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
      onTimeupdate (res) {
      },
      onLoadedmetadata (res) {
      },
      delHandleMouseEnter () {
        this.$refs.carousel.handleMouseEnter = () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
.audio-wrap {
  .carousel {
    width: 100%;
    height: 100vh;
    .carousel-img {
      width: 100%;
      height: 100%;
    }
  }
  .hidden {
    display: none;
  }
}
</style>