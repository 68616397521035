<template>
  <div class="container">
    <img
      class="bg-top"
      :src="`${$api.staticUrl}/subPackages/static/guide/top.png`"
    />
    <img
      class="bg-bottom"
      :src="`${$api.staticUrl}/subPackages/static/guide/bottom.png`"
    />
    <div class="content">
      <div class="c-l">
        <div class="c-title">运动排行榜</div>
        <div class="sub-title">排名数据实时更新</div>
        <img
          class="bg-two"
          :src="`${$api.staticUrl}/subPackages/static/guide/rank_bg03.png`"
        />
        <img
          class="bg-three"
          :src="`${$api.staticUrl}/subPackages/static/guide/rank_bg02.png`"
        />
      </div>
      <div class="c-r">
        <div class="title">{{ start }} - {{ end }}</div>
        <div class="list">
          <div class="l-left">
            <div class="item" v-for="(item, index) in listLeft" :key="index">
              <div>
                <img
                  v-if="index < 3"
                  class="rank"
                  :src="
                    `${$api.staticUrl}/subPackages/static/guide/${index +
                      1}.png`
                  "
                />
                <span class="number" v-else>{{ item.rank }}</span>
                <img class="photo" :src="item.avatar" />
                <span class="name">{{ item.userName }}</span>
              </div>
              <div>
                <span class="runnum">{{
                  item.stepCount | formatNumber(3)
                }}</span
                ><span class="dw">步</span>
              </div>
            </div>
          </div>
          <div class="l-right">
            <div class="item" v-for="(item, index) in listRight" :key="index">
              <div>
                <span class="number">{{ item.rank }}</span>
                <img class="photo" :src="item.avatar" />
                <span class="name">{{ item.userName }}</span>
              </div>
              <div>
                <span class="runnum">{{
                  item.stepCount | formatNumber(3)
                }}</span
                ><span class="dw">步</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      startTime: "",
      endTime: "",
      start: "",
      end: "",
      listLeft: [],
      listRight: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init () {
      this.getTime();
      this.getList();
      this.timer = setInterval(() => {
        this.getTime();
        this.getList();
      }, 20000);
    },
    getTime () {
      var myDate = new Date();
      let year = myDate.getFullYear();
      let month =
        Number(myDate.getMonth() + 1) > 9
          ? myDate.getMonth() + 1
          : "0" + Number(myDate.getMonth() + 1);
      let day =
        Number(myDate.getDate()) > 9
          ? myDate.getDate()
          : "0" + myDate.getDate();
      let hour =
        Number(myDate.getHours()) > 9
          ? myDate.getHours()
          : "0" + myDate.getHours();
      let min =
        Number(myDate.getMinutes()) > 9
          ? myDate.getMinutes()
          : "0" + myDate.getMinutes();
      let seconds =
        Number(myDate.getSeconds()) > 9
          ? myDate.getSeconds()
          : "0" + myDate.getSeconds();
      //   this.startTime = year + "-" + month + "-" + day + " " + "00:00:00";
      // 展示时间
      this.start = year + "." + "01.01";
      this.end = year + "." + month + "." + day;
      // 接口需要
      this.startTime = year + "-" + "01-01" + " " + "00:00:00";
      this.endTime =
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + seconds;
    },
    getList() {
      let params = {
        limit: "100",
        page: 1,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      this.$post(this.$api.rank, params).then((res) => {
        if (!res.code) {
          res.data.list.forEach((item, index) => {
            if (
              index < 10 &&
              res.data.list.length > this.listLeft.length &&
              this.listLeft.length < 10
            ) {
              this.listLeft.push(item);
            } else if (
              index < 20 &&
              res.data.list.length > this.listRight.length &&
              res.data.list.length > 10 &&
              this.listRight.length < 10
            ) {
              this.listRight.push(item);
            }
          });
        }
      });
    },
  },
  beforeDestroy () {
    clearInterval(this.timer);
  }
};
</script>
<style lang="scss" scoped>
.container {
  background: #0e9e5e;
  .bg-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .bg-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .content {
    position: relative;
    display: flex;
    .c-l {
      width: 24%;
      text-align: center;
      padding-top: 7vw;
      .c-title {
        font-size: 56px;
        color: #fff;
      }
      .sub-title {
        font-size: 30px;
        color: #fff;
        margin-top: 2vw;
      }
      .bg-two {
        width: 13vw;
        margin-top: 5vw;
      }
      .bg-three {
        display: block;
        width: 20vw;
        margin: 3vw auto 0 auto;
      }
    }
    .c-r {
      width: 74%;
      background: #20ab6d;
      margin: 3vw;
      border-radius: 16px;
      .title {
        font-size: 32px;
        color: #fff;
        text-align: center;
        margin-top: 3vw;
        font-weight: bold;
      }
      .list {
        margin-top: 1vw;
        display: flex;
        padding: 2vw;
        .l-left {
          flex: 1;
          border-radius: 8px;
          overflow: hidden;
        }
        .l-right {
          flex: 1;
          margin-left: 2vw;
          border-radius: 8px;
          overflow: hidden;
        }
        .item {
          height: 4vw;
          line-height: 4vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          padding: 0 1vw;
          &:nth-child(odd) {
            background: #27b375;
          }
          &:nth-child(even) {
            background: #3ac689;
          }
          .rank {
            width: 3vw;
            vertical-align: middle;
          }
          .number {
            font-size: 24px;
            width: 3vw;
            display: inline-block;
            text-align: center;
            vertical-align: bottom;
          }
          .photo {
            margin-left: 2vw;
            width: 2.6vw;
            height: 2.6vw;
            vertical-align: middle;
            border-radius: 50%;
          }
          .name {
            display: inline-block;
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
            margin-left: 1vw;
            font-size: 20px;
          }
          .runnum {
            font-size: 32px;
            vertical-align: bottom;
          }
          .dw {
            font-size: 18px;
            margin-left: 1vw;
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }
}
</style>
