<template>
  <div class="video-wrap">
    <video 
      ref="videoTag" 
      class="video-tag" 
      :src="`${$api.fileUrl}/${curMaterialInfo.path}`" 
      preload="auto"
      @canplay="onCanplay(curMaterialInfo)"
      @play="onPlay" 
      @error="onError"
      @waiting="onWaiting"
      @pause="onPause" 
      @timeupdate="onTimeupdate" 
      @loadedmetadata="onLoadedmetadata"
      @ended="onended(curMaterialInfo)"
    />
  </div>
</template>

<script>
  export default {
    props: {
      materialList: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        curIndex: 0,
        curMaterialInfo: {}
      }
    },
    watch: {
      materialList: {
        handler: function (newList, oldList) {
          this.curIndex = 0
          this.curMaterialInfo = newList[this.curIndex]
          const videoElement = this.$refs.videoTag
          videoElement && videoElement.play()
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      onCanplay (item) {
        this.startPlay()
        // 用户点播,主动向websocket推送开始播放消息
        item.userId && this.$emit('onPlay', item)
      },
      onended (item) {
        // 如果有下一节目,继续播放下一节目
        this.curIndex = this.curIndex + 1
        if (this.curIndex <= this.materialList.length - 1) {
          this.curMaterialInfo = this.materialList[this.curIndex]
          return false
        }
        // 结束播放消息
        this.$emit('onEnded', item)
      },
      // 开始播放
      startPlay () {
        this.$refs.videoTag.play()
      },
      // 音频暂停
      onPause () {
      },
      // 音频错误
      onError () {
        console.error('audio error:', this.$refs.videoTag?.error?.message ?? 'has error');
      },
      // 音频等待
      onWaiting (res) {
      },
      // 音频播放
      onPlay (res) {
      },
      // timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
      onTimeupdate (res) {
      },
      onLoadedmetadata (res) {
      }
    }
  }
</script>

<style lang="scss" scoped>
.video-wrap {
  .video-tag {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}
</style>